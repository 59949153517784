<!-- 校园安全 > 宿舍考勤 > 考勤明细 -->
<template>
    <div>
        <div v-if="!errorShow">
            <div class="content-wrapper">
                <div class="filter-wrap">
                    <expand-filter
                        marginBottom="0px"
                        borderRadius="0px"
                        :closeWidth="1270"
                        :formData="formData"
                        @clickBtn="clickBtn"
                        @changeBtnFormType="changeBtnFormType"
                        @changeSel="changeSel"
                    ></expand-filter>
                    <div
                        style="
                        flex: 1;
                        display: flex;"
                    >
                        <el-button
                            style="margin-left: auto;margin-right: 10px;"
                            type="enquiry"
                            v-has-permi="['attendanceDetails:export']"
                            @click="handleExport"
                        >导出</el-button>
                    </div>
                </div>
                <div style="border-radius: 4px; overflow: hidden">
                    <results-info
                        v-if="showTitle"
                        v-loading="resultsLoading"
                        :resultsInfo="resultsInfo"
                        :listQuery="listQuery"
                    ></results-info>
                    <table-data
                        ref="table"
                        v-loading="loadingTable"
                        :config="table_config"
                        :tableData="table_data"
                        @handleSelectionChange="handleTableSelectionChange"
                    >
                        <template #abnormalState="{ data }">
                            <div v-if="data.inspectStatus === '3' || data.inspectStatus === '4' ">
                                <span style="color: #75bdfe;cursor: pointer" v-if="data.processingResults" @click="handleOpenAbnormalState(data)">已处理</span>
                                <span style="color: #595959;cursor: pointer" v-else >待处理</span>
                            </div>
                            <div v-else>
                                <span ></span>
                            </div>
                        </template>
                    </table-data>
                    <pagination
                        :total="total"
                        :page.sync="listQuery.pageNum"
                        :limit.sync="listQuery.pageRow"
                        @pagination="getList"
                    />
                    <dialog-wrapper :dialogObj="dialogObj" @handleClose="dialogObj.dialogVisible = false">
                    <div class="processedSty">
                        <div class="processedSty_item">
                            <div class="item_title">处理人</div>
                            <div class="item_value">{{ dialogObjData.processingBy }}</div>
                        </div>
                        <div class="processedSty_item">
                            <div class="item_title">处理时间</div>
                            <div class="item_value">{{ dialogObjData.processingTime }}</div>
                        </div>
                        <div class="processedSty_item">
                            <div class="item_title">处理结果</div>
                            <div class="item_value">{{ dialogObjData.processingResults }}</div>
                        </div>
                    </div>
                </dialog-wrapper>
                </div>
            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    // 业务组件
    DialogWrapper,
    Pagination,
    // 功能组件
    Error,
    Loading,
    // 工具函数
    debounce,
} from "common-local";
import TableData from "./Sub/TableData.vue";
import ResultsInfo from "./CampusSafetyDormitoryAttendanceDetails/Results";
import {mapState} from "vuex";
import {downloadFile, formatTimeObj, formatTreeData} from "@/libs/utils.js";
import {SchoolDormitory} from "@/models/SchoolDormitory";
import ExpandFilter from "./Sub/ExpandFilter";
import onResize from "@/mixins/onResize";

export default {
    mixins: [onResize],
    components: {
        TableData,
        DialogWrapper,
        // 功能组件
        Error,
        Loading,
        Pagination,
        ExpandFilter,
        ResultsInfo,
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
        }),
        exportUrl() {
            return require("@/assets/images/export-btn.png");
        },
        curDay() {
            return formatTimeObj(new Date(), "y-m-d");
        },
    },
    data() {
        return {
            checkType: "1",
            listQuery: {
                schoolId: "",
                organIdList: [],
                dormitoryId: "",
                floorId: "",
                roomName: "",
                pageNum: 1,
                pageRow: 20,
                checkStatus: "",
                inspectStatus: "",
                schedulingDate: "",
                isApp: 2,
                shiftName: "",
            },
            resultsInfo: {
                arriveNum: "",
                checkTotalNum: "",
                compassionateNum: "",
                isFit: false,
                notPresentNames: "",
                shouldArriveNum: "",
                sickNum: "",
            },
            subjects: [],
            dialogObj: {
                title: "已处理",
                dialogVisible: false,
                width: "400px"
            },
            dialogObjData:{},
            total: 0,
            // 头部筛选
            formData: {
                data: [
                    {
                        type: "cascader",
                        label: "",
                        value: [],
                        placeholder: "班级",
                        key: "organIdList",
                        cascaderProps: {
                            label: "name",
                            value: "id",
                            emitPath: false,
                            multiple: true,
                        },
                        list: [],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "核对状态",
                        key: "checkStatus",
                        // 核对状态
                        list: [
                            {label: "待核对", value: 1},
                            {label: "手动核对", value: 2},
                        ],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "检查状态",
                        key: "inspectStatus",
                        list: [
                            {label: "未检查", value: 1},
                            {label: "已检查(一致)", value: 2},
                            {label: "已检查(不一致)", value: 3},
                            {label: "已检查(待补充)", value: 4},
                        ],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "宿舍楼",
                        key: "dormitoryId",
                        list: [],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "楼层",
                        key: "floorId",
                        list: [],
                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "宿舍",
                        key: "roomName",
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "检查班次",
                        key: "shiftName",
                        list: [],
                    },
                    {
                        type: "date",
                        label: "",
                        placeholder: "日期",
                        key: "schedulingDate",
                        valueFormat: "yyyy-MM-dd",
                        value: "",
                        clearable: false,
                        picker: true,
                        format: "yyyy-MM-dd",
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["attendanceDetails:list"],
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                    },
                ],
                btnFormType: true,
            },
            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "宿舍楼",
                        prop: "dormitoryName",
                        align: 'center'
                    },
                    {
                        label: "宿舍",
                        prop: "roomName",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "班级",
                        prop: "organName",
                        labelWidth: "120px",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "姓名",
                        prop: "studentName",
                        type: "popoverText",
                        labelWidth: "120px",
                        align: 'center',
                        maxLength: 8,
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "事假人数",
                        prop: "compassionateNum",
                        align: 'center'
                    },
                    {
                        label: "事假人员",
                        prop: "compassionateNames",
                        type: "popoverText",
                        labelWidth: "120px",
                        maxLength: 8,
                        align: 'center',
                    },
                    {
                        label: "病假人数",
                        prop: "sickNum",
                        align: "center",
                    },
                    {
                        label: "病假人员",
                        prop: "sickNames",
                        type: "popoverText",
                        labelWidth: "120px",
                        maxLength: 8,
                        align: 'center',
                    },
                    {
                        label: "应到人数",
                        prop: "shouldArrive",
                        align: "center",
                    },
                    {
                        label: "核对状态",
                        prop: "checkStatus",
                        type: "function",
                        align: 'center',
                        callBack(row) {
                            return {
                                1: "待核对",
                                2: "手动核对",
                                3: "超时核对",
                                4: "自动核对",
                            }[+row.checkStatus];
                        },
                    },
                    {
                        label: "核对人",
                        prop: "checkTeacherName",
                        align: 'center',
                    },
                    {
                        label: "核对超时",
                        prop: "timeOutStatus",
                        type: "function",
                        align: 'center',
                        callBack(row) {
                            return {
                                1: "未超时",
                                2: "已超时",
                            }[+row.timeOutStatus];
                        },
                    },
                    {
                        label: "核对时间",
                        prop: "checkTime",
                        labelWidth: "180px",
                        type: 'function',
                        align: 'center',
                        callBack: (row) => {
                            return row.checkTime ? row.checkTime.slice(0, -3) : '-'
                        }
                    },
                    {
                        label: "实到人数",
                        prop: "arriveNum",
                        align: "center",
                    },
                    {
                        label: "未到人数",
                        prop: "notPresent",
                        align: "center",
                    },
                    {
                        label: "未到人员",
                        prop: "notPresentNames",
                        type: "popoverText",
                        labelWidth: "120px",
                        maxLength: 8,
                        align: 'center',
                    },
                    {
                        label: "处理内容",
                        prop: "handleDes",
                        type: "popoverText",
                        labelWidth: "200px",
                        align: 'center',
                    },
                    {
                        label: "检查状态",
                        prop: "inspectStatus",
                        type: "function",
                        labelWidth: "120px",
                        align: 'center',
                        callBack(row) {
                            return {
                                1: "未检查",
                                2: "已检查(一致)",
                                3: "已检查(不一致)",
                                4: "已检查(待补充)",
                            }[+row.inspectStatus];
                        },
                    },
                    {
                        label: "检查人",
                        prop: "inspectTeacherName",
                        align: 'center',
                    },
                    {
                        label: "检查超时",
                        prop: "iTimeOutStatus",
                        type: "function",
                        callBack(row) {
                            return {
                                1: "未超时",
                                2: "已超时",
                            }[+row.iTimeOutStatus];
                        },
                        align: 'center',
                    },
                    {
                        label: "检查时间",
                        prop: "inspectTime",
                        labelWidth: "180px",
                        type: "function",
                        align: 'center',
                        callBack(row) {
                            return row.inspectTime ? row.inspectTime.slice(0, 16) : '-'
                        },
                    },
                    {
                        label: "检查班次",
                        prop: "shiftName",
                    },
                    {
                        label: "异常处理状态",
                        labelWidth: "120px",
                        type: "slot",
                        slotName: 'abnormalState',
                        fixed: false,
                        // prop: "abnormalState",
                    },
                ],
                check: true,
                showIndex: false,
                height: "",
            },
            // 表格数据
            table_data: [],
            // 功能组件
            errorShow: false,
            loadingShow: true,
            loadingTable: true,
            tableListSel: [],
            resultsLoading: false,
            showTitle: true,
        };
    },
    async created() {
        this.listQuery.schoolId = this.schoolId;
        this.listQuery.schedulingDate = this.curDay;
        this.formData.data.forEach((item) => {
            if (item.key === "schedulingDate") {
                item.value = this.curDay;
            }
        });

        await this.getCheckType();
        await this.getList();
        await this.getAttendanceDetailStatistics();
        await this.getshiftTypePullDown();
        await this.getDormitoryList();
        await this.getOrgList();
    },
    async mounted() {},
    activated() {
        this.getCheckType();
    },
    methods: {
        /**
         * @Description: 发送请求 获取核对类型
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-04-27 14:06:54
         */
        async getCheckType() {
            await this._fet("/school/dormitoryTimeSetting/getOne", {
                schoolId: this.schoolId,
                timeType: 1,
            }).then((res) => {
                console.log(res.data.data.checkType, "考勤明细");
                this.checkType = res.data.data.checkType;
            });
        },
        async getList(t) {
            this.loadingTable = true;
            this.$forceUpdate();
            if (t === 1) {
                this.listQuery.pageNum = 1;
            }
            const schoolDormitory = new SchoolDormitory();
            let form = JSON.parse(JSON.stringify(this.listQuery));
            await schoolDormitory.getAttendanceDetails(form).then((res) => {
                if (res.data.code === "200") {
                    this.table_data = res.data.data.list;
                    this.total = res.data.data.total;
                } else {
                    this.$message.error(res.data.msg);
                }
                this.loadingTable = false;
            });
        },
        async getDormitoryList() {
            const schoolDormitory = new SchoolDormitory();
            await schoolDormitory
                .getSchoolDormitoryListCondition({
                    schoolId: this.schoolId,
                })
                .then((res) => {
                    if (res.data.code === "200") {
                        this.dormitoryList = res.data.data.map((item) => ({
                            label: item.dormitoryName,
                            value: item.id,
                        }));
                        this.formData.data.forEach((item) => {
                            if (item.key === "dormitoryId") {
                                item.list = this.dormitoryList;
                            }
                        });
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        async getOrgList() {
            const schoolDormitory = new SchoolDormitory();
            await schoolDormitory
                .getDormitoryOrganList({
                    schoolId: this.schoolId,
                })
                .then((res) => {
                    if (res.data.code === "200") {
                        let data = res.data.data;
                        this.formData.data.forEach((item) => {
                            if (item.key === "organIdList") {
                                item.list = Object.freeze(
                                    formatTreeData(data, "id", "parentOrg"),
                                );
                            }
                        });
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        /**
         * @Description:获取检查班次下拉
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-07-06 16:31:54
         */
        getshiftTypePullDown(){
            this._fet("/school/dormitoryTimeSetting/getShiftName", {
                schoolId:this.schoolId
            }).then( (res) => {
                if(res.data.code === '200'){
                    let r = res.data.data;
                    if(r.length >1){
                        this.showTitle = false;
                    }else{
                        this.showTitle = true;
                    }
                    this.$_resizeHandler();
                    let obj = [];
                    r.forEach((item) => {
                       obj.push({
                            label:item,
                            value:item
                        })
                    });
                    this.formData.data.forEach((item) => {
                            if (item.key === "shiftName") {
                                item.list = obj;
                            }
                        });
                }
                },
            );
        },
        /**
         * @Description: 获取宿舍考勤明细统计
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-03-28 10:18:36
         * @param {*} data
         */
        async getAttendanceDetailStatistics() {
            this.resultsLoading = true;
            const schoolDormitory = new SchoolDormitory();
            await schoolDormitory
                .getAttendanceDetailStatistics(this.listQuery)
                .then((res) => {
                    if (res.data.code == "200") {
                        const data = res.data.data;
                        Object.keys(data).forEach((key) => {
                            Object.keys(this.resultsInfo).forEach((subKey) => {
                                if (key == subKey) {
                                    this.resultsInfo[subKey] = data[key];
                                }
                            });
                        });
                        console.log(this.resultsInfo, "this.resultsInfo");
                        this.resultsLoading = false;
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        /**
         * @Description: 打开异常状态弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-07-07 17:55:55
         */
        handleOpenAbnormalState(data){
            this.dialogObjData = data;
            this.dialogObj.dialogVisible = true
        },
        getFloorList(data) {
            const schoolDormitory = new SchoolDormitory();
            return new Promise((resolve) => {
                schoolDormitory
                    .getSchoolDormitoryFloorListCondition({
                        dormitoryId: data,
                        schoolId: this.schoolId,
                    })
                    .then((res) => {
                        if (res.data.code === "200") {
                            resolve(
                                res.data.data.map((item) => ({
                                    label: item.floorName,
                                    value: item.id,
                                })),
                            );
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    });
            });
        },
        changeSel(data) {
            if (data.key === "dormitoryId") {
                this.formData.data.forEach((item) => {
                    if (item.key === "floorId") {
                        item.list = [];
                        item.value = "";
                    }
                });
                if (data.value) {
                    this.formData.data.forEach(async (item) => {
                        if (item.key === "floorId") {
                            item.list = await this.getFloorList(data.value);
                        }
                    });
                }
            }
        },
        clickBtn(data) {
            switch (data.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    this.getList(1);
                    this.getAttendanceDetailStatistics();
                    break;
                case "reset": // 重置
                    this.formData.data.forEach((item) => {
                        if (item.key === "organIdList") {
                            this.listQuery[item.key] = [];
                            item.value = [];
                        } else if (item.key === "floorId") {
                            this.listQuery[item.key] = "";
                            item.value = "";
                            item.list = [];
                        } else if (item.key === "schedulingDate") {
                            item.value = this.curDay;
                        } else {
                            this.listQuery[item.key] = "";
                            item.value = "";
                        }
                    });
                    this.listQuery.schedulingDate = this.curDay;
                    this.getList(1);
                    this.getAttendanceDetailStatistics();
                    break;
                default:
                    break;
            }
        },
        /*
           表格相关操作
           handleTableSelectionChange   表格选中
         */
        handleTableSelectionChange(data) {
            this.tableListSel = data;
        },
        handleExport() {
            let form = {
                schoolId: this.schoolId,
            };
            const filter = this.formData.data.filter((i) => i.value);
            if (filter.length > 0) {
                filter.forEach((item) => {
                    form[item.key] = item.value;
                });
            } else if (this.tableListSel.length > 0) {
                form.ids = this.tableListSel.map((i) => i.id);
            }
            downloadFile(
                {
                    url: "/school/schoolDormitoryDistribution/attendanceDetailsExport",
                    form: form,
                },
                () => {
                    this.$message.success("导出成功");
                },
                () => {},
            );
        },
    },
    watch: {
        checkType: {
            immediate: true,
            handler(newValue, oldValue) {
                console.log(newValue, "checkType");
                this.formData.data[1].list =
                    newValue == 2
                        ? [
                              {label: "待核对", value: 1},
                              {label: "手动核对", value: 2},
                              {label: "自动核对", value: 4},
                          ]
                        : [
                              {label: "待核对", value: 1},
                              {label: "手动核对", value: 2},
                          ];
            },
        },
    },
};
</script>

<style scoped lang="scss">
.content-wrapper{
    padding-right: 10px;
}
.filter-wrap {
    display: flex;
    // justify-content: space-between;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0 10px;
    overflow: hidden;
    margin-bottom: 10px;
    border-radius: 4px;

    .expand-filter {
        padding: 0;
    }

    .operation-btn-view {
        flex-shrink: 0;
    }
}
.export-btn {
    float: right;
    margin: 10px;
    img {
        margin-right: 5px;
        vertical-align: middle;
    }
}
.processedSty{
    .processedSty_item{
        display: flex;
        margin: 0 0 24px 0;
        .item_title{
            width: 80px;
            text-align: right;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-right: 25px;
        }
        .item_value{
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
        }
    }
}
</style>
